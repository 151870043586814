/** 
 * @file Loader.js
 * @brief Application loader
 * @author Efraín Gourcy
 * @modified Feb 01, 2023
 */

import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <span className="loader"></span>
  );
}

export default Loader;