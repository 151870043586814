/** 
 * @file Login.js
 * @brief Login form
 * @author Efraín Gourcy
 * @modified Feb 3, 2023
 */

import React, 
{ useEffect, 
  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, 
  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader';
import { ApiAtencion, 
  settingsWithBody } from '../helpers/ApiUrl';
import { toastify } from '../helpers/Toastify';

const valuesForm = {
  email: "",
  password: ""
};

const Login = ({isLogged,setIsLogged}) => {

  /********************************** variables *************************************************************/
  const [form, setForm] = useState(valuesForm);
  const [isLoading, setisLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  /********************************** useEffect [isLogged] *************************************************************/
  useEffect(() => {
    if (isLogged)
      navigate('/');
  }, [isLogged]);
  
  /********************************** useEffect [] *************************************************************/
  useEffect(() => {
    let username = localStorage.getItem("au.email");
    if (username !== "null" && username !== null) {
      document.getElementById("email").value = username;
      setForm({
        ...form,
        email: username
      });
    }
  }, []);

  /********************************** authAttempt *************************************************************/
  const authAttempt = async () => {
    setisLoading(true);
    try {
      const resp = await fetch(ApiAtencion().url + "login", settingsWithBody('', form, 'POST'));
      const respLogin = await resp.json();

      if (respLogin.code === '401')
        toast.error('Usuario no válido', toastify().error);
      else {
        if (rememberMe)
          localStorage.setItem("au.email", form.email);
        sessionStorage.setItem("au.token", respLogin.authorisation.token);
        setIsLogged(true);
      }

    } catch (error) {
      toast.error('Error de comunicación con el servidor', toastify().error);
    }


    setisLoading(false);

  };

  /********************************** return *************************************************************/
  const handleAccept = (e) => {
    e.preventDefault();

    let error = false;
    let $email = document.getElementById("email");
    let $password = document.getElementById("password");

    $email.classList.remove('ring-2');
    $email.classList.remove('ring-red-500');
    $password.classList.remove('ring-2');
    $password.classList.remove('ring-red-500');

    if (form.email === "") {
      toast.error('No ha introducido un correo electrónico', toastify().error);
      $email.classList.add('ring-2');
      $email.classList.add('ring-red-500');
      error = true;
    } else {
      let validRegexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!form.email.match(validRegexEmail)) {
        toast.error('Correo electrónico no válido', toastify().error);
        $email.classList.add('ring-2');
        $email.classList.add('ring-red-500');
        error = true;
      }
    }

    if (form.password === "") {
      toast.error('No ha introducido una contraseña', toastify().error);
      $password.classList.add('ring-2');
      $password.classList.add('ring-red-500');
      error = true;
    }

    if (!error)
      authAttempt();
  };

  /********************************** return *************************************************************/
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  /********************************** handleRememberMe *************************************************************/
  const handleRememberMe = (e) => {
    if (rememberMe)
      setRememberMe(false);
    else
      setRememberMe(true);
  };

  /********************************** return *************************************************************/
  return (
    <>
      <ToastContainer />
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" />
            Atención de Usuarios
          </a>
          <form>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Ingresa con tus credenciales
                </h1>

                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Correo electrónico</label>
                  <input onChange={handleChange} value={form.username} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="usuario@ccapama.gob.mx" autoFocus />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contraseña</label>
                  <input onChange={handleChange} value={form.password} type="password" name="password" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>

                {isLoading
                  ? <>
                    <div style={{ textAlign: "center", height: "5.2rem" }}>
                      <Loader />
                    </div>
                  </>
                  : <>
                    <div className="flex items-center justify-between" style={{ height: "1.3rem" }}>
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required="" />
                        </div>
                        <div className="ml-3 text-sm">
                          <label onClick={handleRememberMe} htmlFor="remember" className="text-gray-500 dark:text-gray-300">Recuerdame</label>
                        </div>
                      </div>
                      <a href="#" className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">¿Olvidaste tu contraseña?</a>
                    </div>
                    <button onClick={handleAccept} className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Ingresar
                    </button>
                  </>
                }

              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Login;