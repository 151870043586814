/** 
 * @file Entrada.js
 * @brief Registries entries
 * @author Efraín Gourcy
 * @modified Feb 10, 2023
 */

import React, {
  useEffect,
  useState
} from 'react';
import {
  ApiAtencion,
  settingsWithBody,
  settingsWithoutBody
} from '../helpers/ApiUrl';
import {
  ToastContainer,
  toast
} from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  cliente: '',
  motivo: '',
  nombre: '',
  telefono: '',
  genero: ''
}

const Entrada = () => {

  const [form, setForm] = useState(initialValues);
  let navigate = useNavigate();

  /********************************** useEffect [] *************************************************************/
  useEffect(() => {
    const getCatalogoMotivos = async () => {
      const token = sessionStorage.getItem("au.token");
      const resp = await fetch(ApiAtencion().url + "catalogoMotivos/get", settingsWithoutBody(token, 'GET'));
      const respCatalogo = await resp.json();
      if (respCatalogo.status === 'success') {
        let $selectMotivo = document.getElementById('motivo');
        $selectMotivo.innerHTML = '';
        let $option = document.createElement('option');
        $option.value = '-1';
        $option.innerText = 'Selecciona una opción';
        $selectMotivo.appendChild($option);
        respCatalogo.catalogoMotivos.forEach(el => {
          let $option = document.createElement('option');
          $option.value = el.id;
          $option.innerText = el.motivo + " (" + el.descripcion + " )";
          $selectMotivo.appendChild($option);
        });
      }
    };
    getCatalogoMotivos();
  }, []);

  /********************************** store *************************************************************/
  const store = async () => {
    const token = sessionStorage.getItem("au.token");
    const resp = await fetch(ApiAtencion().url + 'atencion/store', settingsWithBody(token, form, 'POST'));
    const respAtencion = await resp.json();
    if (respAtencion.status === 'success')
      navigate('/');
    else {
      toast.error('No se pudo guardar el registro', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  /********************************** hanldeAccept *************************************************************/
  const hanldeAccept = (e) => {
    e.preventDefault();
    let error = false;
    if (form.cliente === '') {
      toast.error('No ha ingresado un número de cliente', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      error = true;
    }
    if (form.motivo === '' || form.motivo === '-1') {
      toast.error('No ha ingresado un motivo', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      error = true;
    }
    if (form.genero === '') {
      toast.error('No ha ingresado un género', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      error = true;
    }
    if (!error)
      store();
  };

  /********************************** hanldeChange *************************************************************/
  const hanldeChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  /********************************** return *************************************************************/
  return (
    <>
      <div className='mt-10 grid grid-cols-4 gap-y-8'>
        <ToastContainer />
        <div className='col-start-2 col-span-2'>
          <label className='block mb-2 text-sm font-medium text-gray-900'>Cliente</label>
          <input type='text' name='cliente' id='cliente' onChange={hanldeChange} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5' />
        </div>

        <div className='col-start-2 col-span-2'>
          <label className='block mb-2 text-sm font-medium text-gray-900'>Motivo</label>
          <select name='motivo' id='motivo' onChange={hanldeChange} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
          </select>
        </div>

        <div className='col-start-2 col-span-2'>
          <label className='block mb-2 text-sm font-medium text-gray-900'>Nombre</label>
          <input type='text' name='nombre' id='nombre' onChange={hanldeChange} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5' />
        </div>

        <div className='col-start-2 col-span-2'>
          <label className='block mb-2 text-sm font-medium text-gray-900'>Género</label>
          <div className="flex items-center mb-4">
            <input onChange={hanldeChange} id="generoH" type="radio" value="H" name="genero" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="generoH" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Hombre</label>
          </div>
          <div className="flex items-center">
            <input onChange={hanldeChange} id="generoM" type="radio" value="M" name="genero" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="generoM" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mujer</label>
          </div>
        </div>

        <div className='col-start-2 col-span-2'>
          <label className='block mb-2 text-sm font-medium text-gray-900'>Teléfono</label>
          <input type='text' name='telefono' id='telefono' onChange={hanldeChange} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5' />
        </div>

        <div className='col-start-2 col-span-2 justify-self-end'>
          <button onClick={hanldeAccept} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center'>Guardar</button>
        </div>

      </div>
    </>
  );
}

export default Entrada;