/** 
 * @file Header.js
 * @brief Header navbar
 * @author Efraín Gourcy
 * @modified Feb 11, 2023
 */

import React, { useEffect, useState } from 'react';
import {
  NavLink
} from 'react-router-dom';
import { ApiAtencion, settingsWithoutBody } from '../helpers/ApiUrl';

const Header = ({ setIsLogged }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  /********************************** useEffect [] *************************************************************/
  useEffect(() => {
    getPermisos();
  }, []);

  /********************************** getPermisos *************************************************************/
  const getPermisos = async () => {
    const token = sessionStorage.getItem('au.token');
    const resp = await fetch(ApiAtencion().url + 'getUserType', settingsWithoutBody(token, 'GET'));
    const respUser = await resp.json();
    if (respUser.type === 'admin')
      setIsAdmin(true);
  };

  /********************************** logOut *************************************************************/
  const logOut = () => {
    sessionStorage.removeItem('au.token');
    setIsLogged(false);
  };

  /********************************** showMenu *************************************************************/
  const showMenu = () => {
    let $menu = document.getElementById('navbar-default');
    if (menuOpen) {
      $menu.classList.add('hidden');
      setMenuOpen(false);
    } else {
      $menu.classList.remove('hidden');
      setMenuOpen(true);
    }
  };

  /********************************** return *************************************************************/
  return (
    <>

      <nav className="bg-blue-50 border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <NavLink to="/" className="flex items-center">
            <img src="https://flowbite.com/docs/images/logo.svg" className="h-6 mr-3 sm:h-9" alt="" />
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Atencion a Usuarios</span>
          </NavLink>
          <button onClick={showMenu} data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <NavLink to="/" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  Inicio
                </NavLink>
              </li>
              <li>
                <NavLink to="/entrada" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  Entrada
                </NavLink>
              </li>
              <li>
                <NavLink to="/salida" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  Salida
                </NavLink>
              </li>
              {isAdmin
                ? <><li>
                  <NavLink to="/reportes/0000-00-00" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                    Reportes
                  </NavLink>
                </li></>
                : <></>
              }
              <li>
                <span onClick={logOut} className="cursor-pointer block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  Salir
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </>
  );
}

export default Header;