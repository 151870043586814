/** 
 * @file Salida.js
 * @brief Exit client attentions
 * @author Efraín Gourcy
 * @modified Feb 10, 2023
 */

import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader';
import { ApiAtencion, settingsWithoutBody } from '../helpers/ApiUrl';

const Salida = () => {

  const [dataClientes, setDataClientes] = useState(null);
  const [dataClientesFull, setDataClientesFull] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalFormData, setModalFormData] = useState({});

  /********************************** getData *************************************************************/
  const getData = async () => {
    setLoading(true);
    const token = sessionStorage.getItem('au.token');
    const resp = await fetch(ApiAtencion().url + 'atencion/get', settingsWithoutBody(token, 'GET'));
    const respCliente = await resp.json();
    if (respCliente.status === 'success')
      setDataClientes(respCliente.atencion);
      setDataClientesFull(respCliente.atencion);
    setLoading(false);
  };

  /********************************** useEffect [] *************************************************************/
  useEffect(() => {
    getData();
  }, []);

  /********************************** hanldeEndProcess *************************************************************/
  const hanldeEndProcess = async (e) => {
    setShowModal(false);
    setLoading(true);
    const token = sessionStorage.getItem('au.token');
    fetch(ApiAtencion().url + 'atencion/finishProcess/' + e.target.dataset.id, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.status === 'success') {
          setDataClientes(null);
          setDataClientesFull(null);
          getData();
        }
      });
  };

  /********************************** showModalForm *************************************************************/
  const showModalForm = (e) => {
    setModalFormData({
      id: e.target.dataset.id,
      cliente: e.target.dataset.cliente
    });
    setShowModal(true);
  };

  /**************** handleSearch ******************************************************************************* */
  const handleSearch = (e) => {
    let search = e.target.value;
    let clientsArray = [];
    if (search.length > 0) {
      clientsArray = dataClientesFull.filter(el => {
        let clientNumber = el.cliente.toString();
        if (clientNumber.includes(search))
          return el;
      });
      setDataClientes(clientsArray);
    } else {
      setDataClientes(dataClientesFull);
    }
    //console.log(clientsArray);
  };

  /********************************** return *************************************************************/
  return (
    <>
      <div className='grid grid-cols-12'>
        <div className="col-start-2 col-span-10 relative overflow-x-auto shadow-md sm:rounded-lg">
          <div className="flex items-center justify-between py-4 bg-white dark:bg-gray-800">
            <div>
            </div>
            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
              </div>
              <input onChange={handleSearch} type="text" id="table-search-users" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Buscar Cliente" />
            </div>
          </div>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Cliente
                </th>
                <th scope="col" className="px-6 py-3">
                  Tiempo
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && <tr><td colSpan="4" className="text-center p-10"><Loader /></td></tr>}
              {dataClientes && dataClientes.map(el => (
                <tr key={el.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img className="rounded-full h-10 w-10 flex items-center justify-center" src={`https://ui-avatars.com/api/?name=${el.nombre}=&background=cbd5e1&color=ffffff`} alt="" />
                    </div>
                    <div className="pl-3">
                      <div className="text-base font-semibold">{el.cliente}</div>
                      <div className="font-normal text-gray-500">{el.nombre}</div>
                      <div className="font-normal text-gray-500">{el.telefono}</div>
                    </div>
                  </th>
                  <td className="px-6 py-4">
                    {el.salida
                      ? <>
                        <span className="text-base text-blue-600 dark:text-blue-500">{el.tiempo}</span>
                      </>
                      : <>
                        <span onClick={showModalForm} data-cliente={el.cliente} data-id={el.id} className="text-base text-blue-600 dark:text-blue-500 cursor-pointer">------</span>
                      </>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    { modalFormData.cliente }
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    Esta seguro de marcar como terminada la atención para el cliente:
                  </p>
                  <p>
                    <span className='text-2xl text-blue-500'>{modalFormData.cliente}</span>
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cerrar
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    data-id={modalFormData.id}
                    onClick={hanldeEndProcess}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Salida;