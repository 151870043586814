/** 
 * @file Session.js
 * @brief Validate user sessions
 * @author Efraín Gourcy
 * @modified Feb 3, 2023
 */

import { ApiAtencion, 
  settingsWithoutBody } from "./ApiUrl";

/**************** validateToken ******************************************************************************* */
const validToken = async (tokenSessionStorage) => {
  try {
    const response = await fetch(ApiAtencion().url + "validToken", settingsWithoutBody(tokenSessionStorage,'GET'));
    const respToken = await response.json();
    if (respToken.message === "Unauthenticated." || respToken.message === 'Unauthorized') {
      sessionStorage.removeItem("au.token");
      return false;
    } else
      return true;
  } catch (err) {
    return false;
  }
};

/**************** validToken ******************************************************************************* */
export const validateToken = () => {
  let tokenSessionStorage = sessionStorage.getItem("au.token");
  if (tokenSessionStorage === null)
    return false;
  else
    return validToken(tokenSessionStorage);
  
};