/** 
 * @file Reportes.js
 * @brief Show Reports & Stats
 * @author Efraín Gourcy
 * @modified Feb 21, 2023
 */

import React, {
  useEffect,
  useState
} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ArcElement,
} from 'chart.js';
import {
  Bar,
  Radar,
  PolarArea,
  Doughnut,
  Line
} from 'react-chartjs-2';
import {
  ApiAtencion,
  settingsWithoutBody
} from '../helpers/ApiUrl';
import { 
  useNavigate, 
  useParams } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ArcElement,
  ChartDataLabels,
);

const Reportes = () => {

  const [ppc, setPpc] = useState(0); const [vdr, setVdr] = useState(0); const [ap, setAp] = useState(0); const [sta, setSta] = useState(0); const [da, setDa] = useState(0); const [ti, setTi] = useState(0); const [ps, setPs] = useState(0); const [bo, setBo] = useState(0); const [acl, setAcl] = useState(0); const [fa, setFa] = useState(0); const [otro, setOtro] = useState(0); const [vmi, setVmi] = useState(0); const [vdm, setVdm] = useState(0); const [vfr, setVfr] = useState(0); const [rn, setRn] = useState(0); const [rtc, setRtc] = useState(0); const [dti, setDti] = useState(0); const [dtc, setDtc] = useState(0); const [dpm, setDpm] = useState(0); const [ddc, setDdc] = useState(0); const [fta, setFta] = useState(0); const [ftc, setFtc] = useState(0); const [tr, setTr] = useState(0);
  const [ppcMax, setPpcMax] = useState('00:00:00'); const [vdrMax, setVdrMax] = useState('00:00:00'); const [apMax, setApMax] = useState('00:00:00'); const [staMax, setStaMax] = useState('00:00:00'); const [daMax, setDaMax] = useState('00:00:00'); const [tiMax, setTiMax] = useState('00:00:00'); const [psMax, setPsMax] = useState('00:00:00'); const [boMax, setBoMax] = useState('00:00:00'); const [aclMax, setAclMax] = useState('00:00:00'); const [faMax, setFaMax] = useState('00:00:00'); const [otroMax, setOtroMax] = useState('00:00:00'); const [vmiMax, setVmiMax] = useState('00:00:00'); const [vdmMax, setVdmMax] = useState('00:00:00'); const [vfrMax, setVfrMax] = useState('00:00:00'); const [rnMax, setRnMax] = useState('00:00:00'); const [rtcMax, setRtcMax] = useState('00:00:00'); const [dtiMax, setDtiMax] = useState('00:00:00'); const [dtcMax, setDtcMax] = useState('00:00:00'); const [dpmMax, setDpmMax] = useState('00:00:00'); const [ddcMax, setDdcMax] = useState('00:00:00'); const [ftaMax, setFtaMax] = useState('00:00:00'); const [ftcMax, setFtcMax] = useState('00:00:00'); const [trMax, setTrMax] = useState('00:00:00');
  const [ppcMin, setPpcMin] = useState('00:00:00'); const [vdrMin, setVdrMin] = useState('00:00:00'); const [apMin, setApMin] = useState('00:00:00'); const [staMin, setStaMin] = useState('00:00:00'); const [daMin, setDaMin] = useState('00:00:00'); const [tiMin, setTiMin] = useState('00:00:00'); const [psMin, setPsMin] = useState('00:00:00'); const [boMin, setBoMin] = useState('00:00:00'); const [aclMin, setAclMin] = useState('00:00:00'); const [faMin, setFaMin] = useState('00:00:00'); const [otroMin, setOtroMin] = useState('00:00:00'); const [vmiMin, setVmiMin] = useState('00:00:00'); const [vdmMin, setVdmMin] = useState('00:00:00'); const [vfrMin, setVfrMin] = useState('00:00:00'); const [rnMin, setRnMin] = useState('00:00:00'); const [rtcMin, setRtcMin] = useState('00:00:00'); const [dtiMin, setDtiMin] = useState('00:00:00'); const [dtcMin, setDtcMin] = useState('00:00:00'); const [dpmMin, setDpmMin] = useState('00:00:00'); const [ddcMin, setDdcMin] = useState('00:00:00'); const [ftaMin, setFtaMin] = useState('00:00:00'); const [ftcMin, setFtcMin] = useState('00:00:00'); const [trMin, setTrMin] = useState('00:00:00');
  const [ppcMinutes, setPpcMinutes] = useState(0); const [vdrMinutes, setVdrMinutes] = useState(0); const [apMinutes, setApMinutes] = useState(0); const [staMinutes, setStaMinutes] = useState(0); const [daMinutes, setDaMinutes] = useState(0); const [tiMinutes, setTiMinutes] = useState(0); const [psMinutes, setPsMinutes] = useState(0); const [boMinutes, setBoMinutes] = useState(0); const [aclMinutes, setAclMinutes] = useState(0); const [faMinutes, setFaMinutes] = useState(0); const [otroMinutes, setOtroMinutes] = useState(0); const [vmiMinutes, setVmiMinutes] = useState(0); const [vdmMinutes, setVdmMinutes] = useState(0); const [vfrMinutes, setVfrMinutes] = useState(0); const [rnMinutes, setRnMinutes] = useState(0); const [rtcMinutes, setRtcMinutes] = useState(0); const [dtiMinutes, setDtiMinutes] = useState(0); const [dtcMinutes, setDtcMinutes] = useState(0); const [dpmMinutes, setDpmMinutes] = useState(0); const [ddcMinutes, setDdcMinutes] = useState(0); const [ftaMinutes, setFtaMinutes] = useState(0); const [ftcMinutes, setFtcMinutes] = useState(0); const [trMinutes, setTrMinutes] = useState(0);
  const [totalUsuarios, setTotalUsuarios] = useState(0);
  const [totalHombres, setTotalHombres] = useState(0);
  const [totalMujeres, setTotalMujeres] = useState(0);
  const [totalSinSalida, setTotalSinSalida] = useState(0);
  const [ppcMaxCliente, setPpcMaxCliente] = useState(0); const [vdrMaxCliente, setVdrMaxCliente] = useState(0); const [apMaxCliente, setApMaxCliente] = useState(0); const [staMaxCliente, setStaMaxCliente] = useState(0); const [daMaxCliente, setDaMaxCliente] = useState(0); const [tiMaxCliente, setTiMaxCliente] = useState(0); const [psMaxCliente, setPsMaxCliente] = useState(0); const [boMaxCliente, setBoMaxCliente] = useState(0); const [aclMaxCliente, setAclMaxCliente] = useState(0); const [faMaxCliente, setFaMaxCliente] = useState(0); const [otroMaxCliente, setOtroMaxCliente] = useState(0); const [vmiMaxCliente, setVmiMaxCliente] = useState(0); const [vdmMaxCliente, setVdmMaxCliente] = useState(0); const [vfrMaxCliente, setVfrMaxCliente] = useState(0); const [rnMaxCliente, setRnMaxCliente] = useState(0); const [rtcMaxCliente, setRtcMaxCliente] = useState(0); const [dtiMaxCliente, setDtiMaxCliente] = useState(0); const [dtcMaxCliente, setDtcMaxCliente] = useState(0); const [dpmMaxCliente, setDpmMaxCliente] = useState(0); const [ddcMaxCliente, setDdcMaxCliente] = useState(0); const [ftaMaxCliente, setFtaMaxCliente] = useState(0); const [ftcMaxCliente, setFtcMaxCliente] = useState(0); const [trMaxCliente, setTrMaxCliente] = useState(0);
  const [ppcPromedio, setPpcPromedio] = useState(0); const [vdrPromedio, setVdrPromedio] = useState(0); const [apPromedio, setApPromedio] = useState(0); const [staPromedio, setStaPromedio] = useState(0); const [daPromedio, setDaPromedio] = useState(0); const [tiPromedio, setTiPromedio] = useState(0); const [psPromedio, setPsPromedio] = useState(0); const [boPromedio, setBoPromedio] = useState(0); const [aclPromedio, setAclPromedio] = useState(0); const [faPromedio, setFaPromedio] = useState(0); const [otroPromedio, setOtroPromedio] = useState(0); const [vmiPromedio, setVmiPromedio] = useState(0); const [vdmPromedio, setVdmPromedio] = useState(0); const [vfrPromedio, setVfrPromedio] = useState(0); const [rnPromedio, setRnPromedio] = useState(0); const [rtcPromedio, setRtcPromedio] = useState(0); const [dtiPromedio, setDtiPromedio] = useState(0); const [dtcPromedio, setDtcPromedio] = useState(0); const [dpmPromedio, setDpmPromedio] = useState(0); const [ddcPromedio, setDdcPromedio] = useState(0); const [ftaPromedio, setFtaPromedio] = useState(0); const [ftcPromedio, setFtcPromedio] = useState(0); const [trPromedio, setTrPromedio] = useState(0);
  //const [isAdmin, setIsAdmin] = useState(false);
  let navigate = useNavigate();
  let { fecha } = useParams();

  /********************************** getPermisos *************************************************************/
  const getPermisos = async () => {
    const token = sessionStorage.getItem('au.token');
    const resp = await fetch(ApiAtencion().url + 'getUserType', settingsWithoutBody(token, 'GET'));
    const respUser = await resp.json();
    if (respUser.type !== 'admin')
      navigate('/');  
  }

  /********************************** useEffect [] *************************************************************/
  useEffect(() => {
    const getData = async () => {
      getPermisos();
      const token = sessionStorage.getItem("au.token");
      const resp = await fetch(ApiAtencion().url + 'atencion/getByDate/' + fecha, settingsWithoutBody(token, 'GET'));
      const respAtencion = await resp.json();

      let addPpc = ppc; let addVdr = vdr; let addAp = ap; let addSta = sta; let addDa = da; let addTi = ti; let addPs = ps; let addBo = bo; let addAcl = acl; let addFa = fa; let addOtro = otro; let addVmi = vmi; let addVdm = vdm; let addVfr = vfr; let addRn = rn; let addRtc = rtc; let addDti = dti; let addDtc = dtc; let addDpm = dpm; let addDdc = ddc; let addFta = fta; let addFtc = ftc; let addTr = tr;
      let timePpcMax = ppcMax; let timeVdrMax = vdrMax; let timeApMax = apMax; let timeStaMax = staMax; let timeDaMax = daMax; let timeTiMax = tiMax; let timePsMax = psMax; let timeBoMax = boMax; let timeAclMax = aclMax; let timeFaMax = faMax; let timeOtroMax = otroMax; let timeVmiMax = vmiMax; let timeVdmMax = vdmMax; let timeVfrMax = vfrMax; let timeRnMax = rnMax; let timeRtcMax = rtcMax; let timeDtiMax = dtiMax; let timeDtcMax = dtcMax; let timeDpmMax = dpmMax; let timeDdcMax = ddcMax; let timeFtaMax = ftaMax; let timeFtcMax = ftcMax; let timeTrMax = trMax;
      let timePpcMin = ppcMin; let timeVdrMin = vdrMin; let timeApMin = apMin; let timeStaMin = staMin; let timeDaMin = daMin; let timeTiMin = tiMin; let timePsMin = psMin; let timeBoMin = boMin; let timeAclMin = aclMin; let timeFaMin = faMin; let timeOtroMin = otroMin; let timeVmiMin = vmiMin; let timeVdmMin = vdmMin; let timeVfrMin = vfrMin; let timeRnMin = rnMin; let timeRtcMin = rtcMin; let timeDtiMin = dtiMin; let timeDtcMin = dtcMin; let timeDpmMin = dpmMin; let timeDdcMin = ddcMin; let timeFtaMin = ftaMin; let timeFtcMin = ftcMin; let timeTrMin = trMin;
      let totalMinutesPpc = 0; let totalMinutesVdr = 0; let totalMinutesAp = 0; let totalMinutesSta = 0; let totalMinutesDa = 0; let totalMinutesTi = 0; let totalMinutesPs = 0; let totalMinutesBo = 0; let totalMinutesAcl = 0; let totalMinutesFa = 0; let totalMinutesOtro = 0; let totalMinutesVmi = 0; let totalMinutesVdm = 0; let totalMinutesVfr = 0; let totalMinutesRn = 0; let totalMinutesRtc = 0; let totalMinutesDti = 0; let totalMinutesDtc = 0; let totalMinutesDpm = 0; let totalMinutesDdc = 0; let totalMinutesFta = 0; let totalMinutesFtc = 0; let totalMinutesTr = 0;
      let promedioPpc = ppcPromedio; let promedioVdr = vdrPromedio; let promedioAp = apPromedio; let promedioSta = staPromedio; let promedioDa = daPromedio; let promedioTi = tiPromedio; let promedioPs = psPromedio; let promedioBo = boPromedio; let promedioAcl = aclPromedio; let promedioFa = faPromedio; let promedioOtro = otroPromedio; let promedioVmi = vmiPromedio; let promedioVdm = vdmPromedio; let promedioVfr = vfrPromedio; let promedioRn = rnPromedio; let promedioRtc = rtcPromedio; let promedioDti = dtiPromedio; let promedioDtc = dtcPromedio; let promedioDpm = dpmPromedio; let promedioDdc = ddcPromedio; let promedioFta = ftaPromedio; let promedioFtc = ftcPromedio; let promedioTr = trPromedio;
      let contTotalUsarios = 0;
      let contTotalHombres = 0;
      let contTotalMujeres = 0;
      let contTotalSinSalida = 0;

      if (respAtencion.status === 'success') {
        respAtencion.atencion.forEach(el => {

          contTotalUsarios++;
          if (el.genero === 'H')
            contTotalHombres++;
          
          if (el.genero === 'M')
            contTotalMujeres++;

          if (el.salida === null || el.salida === 'null')
            contTotalSinSalida++;


          if (el.motivo === 'VMI') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeVmiMax);
              let timeMin = new Date("01/01/2000 " + timeVmiMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesVmi = totalMinutesVmi + minutes;
              if (current > timeMax) {
                timeVmiMax = el.tiempo;
                setVmiMaxCliente(el.cliente);
                if (timeVmiMin === '00:00:00')
                  timeVmiMin = el.tiempo;
              }
              if (timeMin > current || timeVmiMin === '00:00:00')
                timeVmiMin = el.tiempo;
            }
            addVmi++;
          }

          if (el.motivo === 'VDM') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeVdmMax);
              let timeMin = new Date("01/01/2000 " + timeVdmMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesVdm = totalMinutesVdm + minutes;
              if (current > timeMax) {
                timeVdmMax = el.tiempo;
                setVdmMaxCliente(el.cliente);
                if (timeVdmMin === '00:00:00')
                  timeVdmMin = el.tiempo;
              }
              if (timeMin > current || timeVdmMin === '00:00:00')
                timeVdmMin = el.tiempo;
            }
            addVdm++;
          }

          if (el.motivo === 'VFR') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeVfrMax);
              let timeMin = new Date("01/01/2000 " + timeVfrMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesVfr = totalMinutesVfr + minutes;
              if (current > timeMax) {
                timeVfrMax = el.tiempo;
                setVfrMaxCliente(el.cliente);
                if (timeVfrMin === '00:00:00')
                  timeVfrMin = el.tiempo;
              }
              if (timeMin > current || timeVfrMin === '00:00:00')
                timeVfrMin = el.tiempo;
            }
            addVfr++;
          }

          if (el.motivo === 'RTI') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeRnMax);
              let timeMin = new Date("01/01/2000 " + timeRnMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesRn = totalMinutesRn + minutes;
              if (current > timeMax) {
                timeRnMax = el.tiempo;
                setRnMaxCliente(el.cliente);
                if (timeRnMin === '00:00:00')
                  timeRnMin = el.tiempo;
              }
              if (timeMin > current || timeRnMin === '00:00:00')
                timeRnMin = el.tiempo;
            }
            addRn++;
          }

          if (el.motivo === 'RTC') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeRtcMax);
              let timeMin = new Date("01/01/2000 " + timeRtcMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesRtc = totalMinutesRtc + minutes;
              if (current > timeMax) {
                timeRtcMax = el.tiempo;
                setRtcMaxCliente(el.cliente);
                if (timeRtcMin === '00:00:00')
                  timeRtcMin = el.tiempo;
              }
              if (timeMin > current || timeRtcMin === '00:00:00')
                timeRtcMin = el.tiempo;
            }
            addRtc++;
          }

          if (el.motivo === 'DTI') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeDtiMax);
              let timeMin = new Date("01/01/2000 " + timeDtiMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesDti = totalMinutesDti + minutes;
              if (current > timeMax) {
                timeDtiMax = el.tiempo;
                setDtiMaxCliente(el.cliente);
                if (timeDtiMin === '00:00:00')
                  timeDtiMin = el.tiempo;
              }
              if (timeMin > current || timeDtiMin === '00:00:00')
                timeDtiMin = el.tiempo;
            }
            addDti++;
          }

          if (el.motivo === 'DTC') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeDtcMax);
              let timeMin = new Date("01/01/2000 " + timeDtcMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesDtc = totalMinutesDtc + minutes;
              if (current > timeMax) {
                timeDtcMax = el.tiempo;
                setDtcMaxCliente(el.cliente);
                if (timeDtcMin === '00:00:00')
                  timeDtcMin = el.tiempo;
              }
              if (timeMin > current || timeDtcMin === '00:00:00')
                timeDtcMin = el.tiempo;
            }
            addDtc++;
          }

          if (el.motivo === 'DPM') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeDpmMax);
              let timeMin = new Date("01/01/2000 " + timeDpmMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesDpm = totalMinutesDpm + minutes;
              if (current > timeMax) {
                timeDpmMax = el.tiempo;
                setDpmMaxCliente(el.cliente);
                if (timeDpmMin === '00:00:00')
                  timeDpmMin = el.tiempo;
              }
              if (timeMin > current || timeDpmMin === '00:00:00')
                timeDpmMin = el.tiempo;
            }
            addDpm++;
          }

          if (el.motivo === 'DDC') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeDdcMax);
              let timeMin = new Date("01/01/2000 " + timeDdcMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesDdc = totalMinutesDdc + minutes;
              if (current > timeMax) {
                timeDdcMax = el.tiempo;
                setDdcMaxCliente(el.cliente);
                if (timeDdcMin === '00:00:00')
                  timeDdcMin = el.tiempo;
              }
              if (timeMin > current || timeDdcMin === '00:00:00')
                timeDdcMin = el.tiempo;
            }
            addDdc++;
          }

          if (el.motivo === 'FTA') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeFtaMax);
              let timeMin = new Date("01/01/2000 " + timeFtaMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesFta = totalMinutesFta + minutes;
              if (current > timeMax) {
                timeFtaMax = el.tiempo;
                setFtaMaxCliente(el.cliente);
                if (timeFtaMin === '00:00:00')
                  timeFtaMin = el.tiempo;
              }
              if (timeMin > current || timeFtaMin === '00:00:00')
                timeFtaMin = el.tiempo;
            }
            addFta++;
          }

          if (el.motivo === 'FTC') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeFtcMax);
              let timeMin = new Date("01/01/2000 " + timeFtcMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesFtc = totalMinutesFtc + minutes;
              if (current > timeMax) {
                timeFtcMax = el.tiempo;
                setFtcMaxCliente(el.cliente);
                if (timeFtcMin === '00:00:00')
                  timeFtcMin = el.tiempo;
              }
              if (timeMin > current || timeFtcMin === '00:00:00')
                timeFtcMin = el.tiempo;
            }
            addFtc++;
          }

          if (el.motivo === 'TR') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeTrMax);
              let timeMin = new Date("01/01/2000 " + timeTrMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesTr = totalMinutesTr + minutes;
              if (current > timeMax) {
                timeTrMax = el.tiempo;
                setTrMaxCliente(el.cliente);
                if (timeTrMin === '00:00:00')
                  timeTrMin = el.tiempo;
              }
              if (timeMin > current || timeTrMin === '00:00:00')
                timeTrMin = el.tiempo;
            }
            addTr++;
          }

          if (el.motivo === 'OTRO') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeOtroMax);
              let timeMin = new Date("01/01/2000 " + timeOtroMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesOtro = totalMinutesOtro + minutes;
              if (current > timeMax) {
                timeOtroMax = el.tiempo;
                setOtroMaxCliente(el.cliente);
                if (timeOtroMin === '00:00:00')
                  timeOtroMin = el.tiempo;
              }
              if (timeMin > current || timeOtroMin === '00:00:00')
                timeOtroMin = el.tiempo;
            }
            addOtro++;
          }

          if (el.motivo === 'FA') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeFaMax);
              let timeMin = new Date("01/01/2000 " + timeFaMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesFa = totalMinutesFa + minutes;
              if (current > timeMax) {
                timeFaMax = el.tiempo;
                setFaMaxCliente(el.cliente);
                if (timeFaMin === '00:00:00')
                  timeFaMin = el.tiempo;
              }
              if (timeMin > current || timeFaMin === '00:00:00')
                timeFaMin = el.tiempo;
            }
            addFa++;
          }

          if (el.motivo === 'ACL') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeAclMax);
              let timeMin = new Date("01/01/2000 " + timeAclMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesAcl = totalMinutesAcl + minutes;
              if (current > timeMax) {
                timeAclMax = el.tiempo;
                setAclMaxCliente(el.cliente);
                if (timeAclMin === '00:00:00')
                  timeAclMin = el.tiempo;
              }
              if (timeMin > current || timeAclMin === '00:00:00')
                timeAclMin = el.tiempo;
            }
            addAcl++;
          }

          if (el.motivo === 'BO') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeBoMax);
              let timeMin = new Date("01/01/2000 " + timeBoMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesBo = totalMinutesBo + minutes;
              if (current > timeMax) {
                timeBoMax = el.tiempo;
                setBoMaxCliente(el.cliente);
                if (timeBoMin === '00:00:00')
                  timeBoMin = el.tiempo;
              }
              if (timeMin > current || timeBoMin === '00:00:00')
                timeBoMin = el.tiempo;
            }
            addBo++;
          }

          if (el.motivo === 'PS') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timePsMax);
              let timeMin = new Date("01/01/2000 " + timePsMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesPs = totalMinutesPs + minutes;
              if (current > timeMax) {
                timePsMax = el.tiempo;
                setPsMaxCliente(el.cliente);
                if (timePsMin === '00:00:00')
                  timePsMin = el.tiempo;
              }
              if (timeMin > current || timePsMin === '00:00:00')
                timePsMin = el.tiempo;
            }
            addPs++;
          }

          if (el.motivo === 'TI') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeTiMax);
              let timeMin = new Date("01/01/2000 " + timeTiMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesTi = totalMinutesTi + minutes;
              if (current > timeMax) {
                timeTiMax = el.tiempo;
                setTiMaxCliente(el.cliente);
                if (timeTiMin === '00:00:00')
                  timeTiMin = el.tiempo;
              }
              if (timeMin > current || timeTiMin === '00:00:00')
                timeTiMin = el.tiempo;
            }
            addTi++;
          }

          if (el.motivo === 'DA') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeDaMax);
              let timeMin = new Date("01/01/2000 " + timeDaMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesDa = totalMinutesDa + minutes;
              if (current > timeMax) {
                timeDaMax = el.tiempo;
                setDaMaxCliente(el.cliente);
                if (timeDaMin === '00:00:00')
                  timeDaMin = el.tiempo;
              }
              if (timeMin > current || timeDaMin === '00:00:00')
                timeDaMin = el.tiempo;
            }
            addDa++;
          }

          if (el.motivo === 'STA') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeStaMax);
              let timeMin = new Date("01/01/2000 " + timeStaMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesSta = totalMinutesSta + minutes;
              if (current > timeMax) {
                timeStaMax = el.tiempo;
                setStaMaxCliente(el.cliente);
                if (timeStaMin === '00:00:00')
                  timeStaMin = el.tiempo;
              }
              if (timeMin > current || timeStaMin === '00:00:00')
                timeStaMin = el.tiempo;
            }
            addSta++;
          }

          if (el.motivo === 'AP') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeApMax);
              let timeMin = new Date("01/01/2000 " + timeApMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesAp = totalMinutesAp + minutes;
              if (current > timeMax) {
                timeApMax = el.tiempo;
                setApMaxCliente(el.cliente);
                if (timeApMin === '00:00:00')
                  timeApMin = el.tiempo;
              }
              if (timeMin > current || timeApMin === '00:00:00')
                timeApMin = el.tiempo;
            }
            addAp++;
          }

          if (el.motivo === 'VDR') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timeVdrMax);
              let timeMin = new Date("01/01/2000 " + timeVdrMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesVdr = totalMinutesVdr + minutes;
              if (current > timeMax) {
                timeVdrMax = el.tiempo;
                setVdrMaxCliente(el.cliente);
                if (timeVdrMin === '00:00:00')
                  timeVdrMin = el.tiempo;
              }
              if (timeMin > current || timeVdrMin === '00:00:00')
                timeVdrMin = el.tiempo;
            }
            addVdr++;
          }

          if (el.motivo === 'PPC') {
            if (el.tiempo !== null) {
              let current = new Date("01/01/2000 " + el.tiempo);
              let timeMax = new Date("01/01/2000 " + timePpcMax);
              let timeMin = new Date("01/01/2000 " + timePpcMin);
              let a = el.tiempo.split(':');
              let minutes = ((+a[0]) * 60 + (+a[1])) +1;
              totalMinutesPpc = totalMinutesPpc + minutes;
              if (current > timeMax) {
                timePpcMax = el.tiempo;
                setPpcMaxCliente(el.cliente);
                if (timePpcMin === '00:00:00')
                  timePpcMin = el.tiempo;
              }
              if (timeMin > current || timePpcMin === '00:00:00')
                timePpcMin = el.tiempo;
            }
            addVdr++;
          }

        });
      }
      setPpc(addPpc); setVdr(addVdr); setAp(addAp); setSta(addSta); setDa(addDa); setTi(addTi); setPs(addPs); setBo(addBo); setAcl(addAcl); setFa(addFa); setOtro(addOtro); setVmi(addVmi); setVdm(addVdm); setVfr(addVfr); setRn(addRn); setRtc(addRtc); setDti(addDti); setDtc(addDtc); setDpm(addDpm); setDdc(addDdc); setFta(addFta); setFtc(addFtc); setTr(addTr);
      setPpcMax(timePpcMax); setVdrMax(timeVdrMax); setApMax(timeApMax); setStaMax(timeStaMax); setDaMax(timeDaMax); setTiMax(timeTiMax); setPsMax(timePsMax); setBoMax(timeBoMax); setAclMax(timeAclMax); setFaMax(timeFaMax); setOtroMax(timeOtroMax); setVmiMax(timeVmiMax); setVdmMax(timeVdmMax); setVfrMax(timeVfrMax); setRnMax(timeRnMax); setRtcMax(timeRtcMax); setDtiMax(timeDtiMax); setDtcMax(timeDtcMax); setDpmMax(timeDpmMax); setDdcMax(timeDdcMax); setFtaMax(timeFtaMax); setFtcMax(timeFtcMax); setTrMax(timeTrMax);
      setPpcMin(timePpcMin); setVdrMin(timeVdrMin); setApMin(timeApMin); setStaMin(timeStaMin); setDaMin(timeDaMin); setTiMin(timeTiMin); setPsMin(timePsMin); setBoMin(timeBoMin); setAclMin(timeAclMin); setFaMin(timeFaMin); setOtroMin(timeOtroMin); setVmiMin(timeVmiMin); setVdmMin(timeVdmMin); setVfrMin(timeVfrMin); setRnMin(timeRnMin); setRtcMin(timeRtcMin); setDtiMin(timeDtiMin); setDtcMin(timeDtcMin); setDpmMin(timeDpmMin); setDdcMin(timeDdcMin); setFtaMin(timeFtaMin); setFtcMin(timeFtcMin); setTrMin(timeTrMin);
      setPpcMinutes(totalMinutesPpc); setVdrMinutes(totalMinutesVdr); setApMinutes(totalMinutesAp); setStaMinutes(totalMinutesSta); setDaMinutes(totalMinutesDa); setTiMinutes(totalMinutesTi); setPsMinutes(totalMinutesPs); setBoMinutes(totalMinutesBo); setAclMinutes(totalMinutesAcl); setFaMinutes(totalMinutesFa); setOtroMinutes(totalMinutesOtro); setVmiMinutes(totalMinutesVmi); setVdmMinutes(totalMinutesVdm); setVfrMinutes(totalMinutesVfr); setRnMinutes(totalMinutesRn); setRtcMinutes(totalMinutesRtc); setDtiMinutes(totalMinutesDti); setDtcMinutes(totalMinutesDtc); setDpmMinutes(totalMinutesDpm); setDdcMinutes(totalMinutesDdc); setFtaMinutes(totalMinutesFta); setFtcMinutes(totalMinutesFtc); setTrMinutes(totalMinutesTr);
      setTotalUsuarios(contTotalUsarios);
      setTotalHombres(contTotalHombres);
      setTotalMujeres(contTotalMujeres);
      setTotalSinSalida(contTotalSinSalida);
      setPpcPromedio(totalMinutesPpc/addPpc); setVdrPromedio(totalMinutesVdr/addVdr); setApPromedio(totalMinutesAp/addAp); setStaPromedio(totalMinutesSta/addSta); setDaPromedio(totalMinutesDa/addDa); setTiPromedio(totalMinutesTi/addTi); setPsPromedio(totalMinutesPs/addPs); setBoPromedio(totalMinutesBo/addBo); setAclPromedio(totalMinutesAcl/addAcl); setFaPromedio(totalMinutesFa/addFa); setOtroPromedio(totalMinutesOtro/addOtro); setVmiPromedio(totalMinutesVmi/addVmi); setVdmPromedio(totalMinutesVdm/addVdm); setVfrPromedio(totalMinutesVfr/addVfr); setRnPromedio(totalMinutesRn/addRn); setRtcPromedio(totalMinutesRtc/addRtc); setDtiPromedio(totalMinutesDti/addDti); setDtcPromedio(totalMinutesDtc/addDtc); setDpmPromedio(totalMinutesDpm/addDpm); setDdcPromedio(totalMinutesDdc/addDdc); setFtaPromedio(totalMinutesFta/addFta); setFtcPromedio(totalMinutesFtc/addFtc); setTrPromedio(totalMinutesTr/addTr);

    };
    getData();
  }, [])

  /********************************** variables *************************************************************/
  const optionsBar1 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: "Número de Clientes por Motivo de Visita: " + totalUsuarios,
      },
      datalabels: {
        color: '#36A2EB'
      },
    },
  };


  const labels = ['VMI', 'VDM', 'VFR', 'RTI', 'RTC', 'DTI', 'DTC', 'DPM', 'DDC', 'FTA', 'FTC', 'TR', 'FA', 'ACL', 'BO', 'PS', 'TI', 'DA', 'STA', 'AP', 'VDR', 'PPC'];

  const dataBar1 = {
    labels,
    datasets: [
      {
        label: 'No. de Clientes',
        data: [vmi, vdm, vfr, rn, rtc, dti, dtc, dpm, ddc, fta, ftc, tr, fa, acl, bo, ps, ti, da, sta, ap, vdr, ppc],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }
    ],
  };

  /*
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },
  };
  */

  const data = {
    labels: ['VMI', 'VDM', 'VFR', 'RTI', 'RTC', 'DTI', 'DTC', 'DPM', 'DDC', 'FTA', 'FTC', 'TR', 'FA', 'ACL', 'BO', 'PS', 'TI', 'DA', 'STA', 'AP', 'VDR', 'PPC'],
    datasets: [
      {
        label: 'Minutos en Atención a Clientes',
        data: [vmiMinutes, vdmMinutes, vfrMinutes, rnMinutes, rtcMinutes, dtiMinutes, dtcMinutes, dpmMinutes, ddcMinutes, ftaMinutes, ftcMinutes, trMinutes, faMinutes, aclMinutes, boMinutes, psMinutes, tiMinutes, daMinutes, staMinutes, apMinutes, vdrMinutes, ppcMinutes],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: 'rgba(53, 162, 235, 0.5)',
        borderWidth: 5,
      }
    ],
  };

  const data2 = {
    //labels: ['VMI', 'VDM', 'VFR', 'RTI', 'RTC', 'DTI', 'DTC', 'DPM', 'DDC', 'FTA', 'FTC', 'TR', 'FA', 'ACL', 'BO', 'PS', 'TI', 'DA', 'STA', 'AP', 'VDR'],
    datasets: [
      {
        label: 'Minutos en Atención a Clientes',
        data: [vmiMinutes, vdmMinutes, vfrMinutes, rnMinutes, rtcMinutes, dtiMinutes, dtcMinutes, dpmMinutes, ddcMinutes, ftaMinutes, ftcMinutes, trMinutes, faMinutes, aclMinutes, boMinutes, psMinutes, tiMinutes, daMinutes, staMinutes, apMinutes, vdrMinutes, ppcMinutes],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)',
          'rgba(255, 159, 64, 0.5)',
          'rgba(170, 183, 184, 0.5)',
          'rgba(120, 40, 31, 0.5)',
          'rgba(81, 46, 95, 0.5)',
          'rgba(27, 79, 114, 0.5)',
          'rgba(112, 123, 124, 0.5)',
          'rgba(249, 231, 159, 0.5)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataPie = {
    labels: ['Hombres', 'Mujeres'],
    datasets: [
      {
        label: 'Hombre/Mujeres',
        data: [totalHombres, totalMujeres],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataPie2 = {
    labels: ['Entrada/Salida', 'Sin Marcar Salida'],
    datasets: [
      {
        label: '',
        data: [(totalUsuarios-totalSinSalida), totalSinSalida],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };


  /********************************** return *************************************************************/
  return (
    <>

      <div className='grid md:grid-cols-10 md:mt-10 md:gap-3'>

        <div className='md:col-start-2 md:col-span-8 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div className='text-2xl'>Tiempos máximos y mínimos</div>
          </div>
        </div>

        <div className='md:col-start-2 md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>VMI</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{vmiMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{vmiMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{vmiMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{vmiPromedio ? vmiPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>VDM</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{vdmMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{vdmMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{vdmMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{vdmPromedio ? vdmPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>VFR</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{vfrMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{vfrMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{vfrMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{vfrPromedio ? vfrPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>RTI</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{rnMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{rnMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{rnMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{rnPromedio ? rnPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 md:col-start-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>RTC</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{rtcMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{rtcMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{rtcMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{rtcPromedio ? rtcPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>DTI</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{dtiMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{dtiMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{dtiMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{dtiPromedio ? dtiPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>DTC</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{dtcMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{dtcMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{dtcMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{dtcPromedio ? dtcPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>DPM</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{dpmMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{dpmMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{dpmMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{dpmPromedio ? dpmPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 md:col-start-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>DDC</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{ddcMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{ddcMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{ddcMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{ddcPromedio ? ddcPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>FTA</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{ftaMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{ftaMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{ftaMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{ftaPromedio ? ftaPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>FTC</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{ftcMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{ftcMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{ftcMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{ftcPromedio ? ftcPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>TR</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{trMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{trMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{trMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{trPromedio ? trPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 md:col-start-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>FA</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{faMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{faMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{faMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{faPromedio ? faPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>ACL</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{aclMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{aclMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{aclMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{aclPromedio ? aclPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>BO</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{boMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{boMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{boMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{boPromedio ? boPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>PS</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{psMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{psMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{psMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{psPromedio ? psPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 md:col-start-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>TI</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{tiMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{tiMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{tiMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{tiPromedio ? tiPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>DA</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{daMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{daMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{daMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{daPromedio ? daPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>STA</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{staMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{staMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{staMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{staPromedio ? staPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>AP</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{apMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{apMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{apMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{apPromedio ? apPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 md:col-start-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>VDR</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{vdrMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{vdrMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{vdrMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{vdrPromedio ? vdrPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:col-span-2 bg-blue-100 p-2 rounded-lg text-center'>
          <div className='bg-blue-300 rounded'>
            <div>PPC</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Máximo</div>
            <div className='text-lg'>{ppcMax}</div>
            <div>Cliente</div>
            <div className='text-lg'>{ppcMaxCliente}</div>
          </div>
          <div className='border-b-4 border-blue-300'>
            <div>Mínimo</div>
            <div className='text-lg'>{ppcMin}</div>
          </div>
          <div>
            <div>Promedio</div>
            <div className='text-lg'>{ppcPromedio ? ppcPromedio.toFixed(2) : '0'} minutos</div>
          </div>
        </div>

        <div className='md:mt-10 md:col-start-2 md:col-span-8'>
          <Bar options={optionsBar1} data={dataBar1} />
        </div>

        <div className='md:mt-10 md:col-start-2 md:col-span-8'>
          <Line data={data} />
        </div>

        {/*
        <div className='md:mt-10 md:col-start-2 md:col-span-4'>
          <Radar data={data} />
        </div>

        <div className='md:mt-10 md:col-span-4'>
          <PolarArea data={data2} />
        </div>
        */}

        <div className='md:mt-10 md:col-start-2 md:col-span-4'>
          <Doughnut data={dataPie} />
        </div>

        <div className='md:mt-10 md:col-span-4'>
          <Doughnut data={dataPie2} />
        </div>

      </div>

    </>
  );
}

export default Reportes;