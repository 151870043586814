/** 
* @file Toastify.js
* @brief Toastify settings
* @author Efraín Gourcy
* @modified Jan 30, 2023
*/

export const toastify = () => {
  const error = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  }
  return { error };
}