/** 
 * @file App.js
 * @brief Beginning of the application
 * @author Efraín Gourcy
 * @modified Feb 11, 2023
 */

import {
  useState
} from 'react';
import {
  Route,
  Routes
} from 'react-router-dom';
import Login from './pages/Login';
import { validateToken } from "./helpers/Session";
import Clientes from './pages/Clientes';
import Header from './pages/Header';
import Entrada from './pages/Entrada';
import Salida from './pages/Salida';
import Reportes from './pages/Reportes';

function App() {

  /********************************** variables *************************************************************/
  const [isLogged, setIsLogged] = useState(false);

  /******************************************************** if (!isLogged) ********************************************************/
  if (!isLogged) {
    if (validateToken())
      setIsLogged(true);
  }

  /********************************** return *************************************************************/
  return (
    <>
      {isLogged
        ? <>
          <Header setIsLogged={setIsLogged} />
          <Routes>
            <Route exact path="/" element={<Clientes />} />
            <Route exact path="/login" element={<Login isLogged={isLogged} setIsLogged={setIsLogged} />} />
            <Route exact path="/entrada" element={<Entrada />} />
            <Route exact path="/salida" element={<Salida />} />
            <Route exact path="/reportes/:fecha" element={<Reportes />} />
          </Routes>
        </>
        : <>
          <Login isLogged={isLogged} setIsLogged={setIsLogged} />
        </>
      }

    </>
  );
}

export default App;
