/** 
* @file ApiUrl.js
* @brief Api url
* @author Efraín Gourcy
* @modified Feb 2, 2023
*/

/********************************** ApiUsuarios *************************************************************/
export const ApiAtencion = () => {
  //const url = "http://localhost/api-atencion/public/api/v1/";
  const url = "https://apiatencion.gourcy.com.mx/api/v1/";
  return { url };
}

/********************************** settingsWithBody *************************************************************/
export const settingsWithBody = (token,params,method) => {
  const settings = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(params)
  };
  return settings;
};

/********************************** settingsWithoutBody *************************************************************/
export const settingsWithoutBody = (token,method) => {
  const settings = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  return settings;
};